import React from 'react'
import {Link} from 'react-router-dom'
import { Grid } from '@mui/material/';
import getClients from '../../../services/clientList'

const Designs = (props) => {
    let clientsList = [];
    getClients(props.clients, clientsList);
    const designProperties = [
                              {route: '/omni-design1', title: 'Agency Only', img: 'previews/Design1.png', alt: 'Design 1 Preview', client: 'Omni'},
                              {route: '/omni-design8', title: 'Design 8', img: 'previews/Design8.png', alt: 'Design 8 Preview', client: 'Omni'},
                              {route: '/omni-design4', title: 'Design 4', img: 'previews/Design4.png', alt: 'Design 4 Preview', client: 'Omni'},
                              {route: '/Prospecting', title: 'Prospecting', img: 'previews/Prospecting.png', alt: 'Prospecting Preview', client: 'Omni'},
                              {route: '/Retargeting', title: 'Retargeting', img: 'previews/Retargeting.png', alt: 'Retargeting Preview', client: 'Omni'},
    ]

        return (
        <Grid container rowSpacing={2} columnSpacing={5} sx={{justifyContent:"center"}}>
            {designProperties.map((design) => {
                if (clientsList.includes(design.client)) {
                    return (
                    <Grid item xs={4} key={Math.random().toString(16).slice(2)}>
                        <Link to={design.route} key={Math.random().toString(16).slice(2)}>
                            <img width="300" height="250" src={design.img} alt={design.alt}
                                 key={Math.random().toString(16).slice(2)}/>
                        </Link>
                        <h1 key={Math.random().toString(16).slice(2)}>{design.title}</h1>
                    </Grid>
            )} else {
                    return (<div key={Math.random().toString(16).slice(2)}></div>)
                }
            })}

        </Grid>
    );
}

export default Designs;
