import { IFrame } from './IFrame';

export default function _300x250(props){

    return (
        <>
            <IFrame {...props} iframenumber={0} width={"300"} height={"250"} title={"_300x250-Banner"} id={"_300x250-Banner"} src={'/Omni_Hotels/dynamic-banners-omni-hotels/Retargeting_Q1/studio/omni_retargeting_Q12023/omni_retargeting_Q12023_300x250/index.html'}/>
        </>
    );
}
