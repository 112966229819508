import { IFrame } from './IFrame';

export default function _300x600(props){

  return (
      <>
        <IFrame{...props} iframenumber={4}  width={"300"} height={"600"} title={"_300x600-Banner"} id={"_300x600-Banner"} src={'/Omni_Hotels/dynamic-banners-omni-hotels/Prospecting_Q1/studio/omni_Q12023/omni_Q12023_300x600/index.html'}/>
      </>
  );
}
